import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import translationAUEN from './content/au/en.json';
import translationSGEN from './content/sg/en.json';
import { DEBUG_TRANSLATION, ALLOWED_COUNTRIES, ALLOWED_LANGUAGES, ROUTES } from './constants';

// Construct the URL dynamically
const path = window.location.pathname;
const parts = path.split('/');

const countryCode = parts[1];
const languageCode = parts[2];

if(path.includes(ROUTES.NOTFOUND) || path.includes(ROUTES.PING)){
  /* empty */ // - This is to stop again and agin whether the page contain both country and language code or not
}else if(ALLOWED_COUNTRIES.includes(countryCode) && !ALLOWED_LANGUAGES.includes(languageCode)){
  window.location.href = `/${countryCode}${ROUTES.NOTFOUND}`;
}else if (!ALLOWED_COUNTRIES.includes(countryCode) || !ALLOWED_LANGUAGES.includes(languageCode)) {
  window.location.href = ROUTES.NOTFOUND;
}

let enTranslationJson = {};

if (languageCode && languageCode === 'en') {
  if (countryCode === 'au') {
    enTranslationJson = translationAUEN;
  } else if (countryCode === 'sg') {
    enTranslationJson = translationSGEN;
  }
}else{
  enTranslationJson = translationSGEN;
}

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: enTranslationJson
  }
};

// eslint-disable-next-line no-underscore-dangle
const debugTranslation = window?._env_?.REACT_APP_DEBUG_TRANSLATION || process.env.REACT_APP_DEBUG_TRANSLATION;

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: debugTranslation === DEBUG_TRANSLATION, // Set to true if you want to see logging
    resources,
    fallbackLng: 'en', // Use 'en' if detected lang is not available
    lng: ALLOWED_LANGUAGES.includes(languageCode) ? languageCode : 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;