import {nanoid}  from 'nanoid';
import { CountryLanguageObj } from '../../user/index';
import {
  ACCESS_TOKEN_KEY,
  SESSION_ID,
} from '../../../constants';


const getUserNameFromAccessToken = () => {
  let userName = '';
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (accessToken) {
    const refreshTokenURL = accessToken.split('.')[1];
    const decodedToken = JSON.parse(window.atob(refreshTokenURL));
    userName = decodedToken?.email;
  }

  return userName;
};

const getCountrySpecificDetails = (countryLanguageObj: CountryLanguageObj) => {
  const countryCode =
  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
  (window as any)?._env_?.REACT_APP_COUNTRY_SPECIFIC_SETTINGS || process.env.REACT_APP_COUNTRY_SPECIFIC_SETTINGS;
    
  const containsSlashQuote = countryCode.includes('\\"');
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let countryCodeData: any;
  if(containsSlashQuote){
    const modifiedString = countryCode.replace(/\\"/g, '"');
    countryCodeData = JSON.parse(modifiedString);
  }else{
    countryCodeData = JSON.parse(countryCode);
  }  

  return countryCodeData[countryLanguageObj.countryCode][countryLanguageObj.languageCode];
}

const setThemePrefixBasedOnCountry = () => {

  const path = window.location.pathname;
  const parts = path.split('/');
  const countryCode = parts[1];

  let themePrefix = 'na';

  if (countryCode === 'au') {
    themePrefix = 'sd';
  } else if (countryCode === 'sg') {
    themePrefix = 'na';
  }

  return themePrefix;
}

const storeAndRetriveSessionId = (action: string) => {
  const sessionId = localStorage.getItem(SESSION_ID);
  if (sessionId && !action) {
    return sessionId;
  }
  const tempSessionId = nanoid(30);
  localStorage.setItem(SESSION_ID, tempSessionId);
  return tempSessionId;

}

export { storeAndRetriveSessionId, getUserNameFromAccessToken, getCountrySpecificDetails, setThemePrefixBasedOnCountry };
