import { useMutation, useQuery } from '@tanstack/react-query';
import { ScoreData } from '@neurowyzr/nw-dragonfly-lib/dist/types';
import { appendRequestIDWithRetryCount, generateUniqueID, shouldRetryAPI } from '../utils';
import { INCREMENT_ONE, INITIAL_RETRY_COUNT, LAST_SESSION_ID } from '../../constants';
import { CountryLanguageObj, Error, SurveyPayload } from '../user/index';
import API_URLS from '../endpoints';
import axiosInstance from '../axios';
import DataQueryKeys from '../data-query-keys';
import { getCountrySpecificDetails } from '../utils/user/login';

interface ReportStatusData {
  session_id: string,
  is_score_ready: boolean
}

interface RedoGameStatusData {
  is_allowed: boolean
}

interface StartSessionPayload {
  sessionId: string;
  countryLanguageObj: CountryLanguageObj;
  countryCode: string;
}

interface UpdateSessionPayload {
  sessionId: string;
  payload: {
    email: string;
  }
}

interface UpdateUserRegistrationPayload {
  emailIdOrSessionId: string;
  payload: {
    email: string;
    name: string;
    birth_year: number;
    gender: string;
  }
}

interface UpdateUserConsentPayload {
  payload: {
    is_data_consent: boolean;
  }
}

interface VerifyOtpPayload {
  sessionId: string;
  payload: {
    email: string;
    otp: string;
    name?: string;
  }
}

interface VerifyOtpConfig {
  jwt: string;
}

export interface UserRegisteredDetails {
  email: string;
  name: string;
  birth_year: number;
  gender: string;
  is_data_consent: boolean;
  isTermsChecked?: boolean;
}

interface AddUserSurveyPayload {
  sessionId: string;
  payload: SurveyPayload,
}

export const userScoreInitialData = {
  overall_latest_score: 0,
  overall_score_is_flagged: false,
  latest_session_id: '',
  scores: {
    attention: [],
    executive_function: [],
    working_memory: [],
    overall: [],
  },
}

export const initialUserRegisteredDetailData = {
  email: '',
  name: '',
  birth_year: 0,
  gender: '',
  is_data_consent: false,
  isTermsChecked: true
}

function useGetUserRegisteredDetails(isCalled: boolean) {
  let retryCount = INITIAL_RETRY_COUNT;
  const requestID = generateUniqueID();

  return useQuery<UserRegisteredDetails, Error>(
    [DataQueryKeys.USER_DETAILS],
    async () => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);
      const response = await axiosInstance.get(API_URLS.deleteOrGetUserAccount(), {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });

      const tempUserRegisteredDetailsData = response.data;
      tempUserRegisteredDetailsData.isTermsChecked = true;

      const userRegisteredDetailsData: UserRegisteredDetails = tempUserRegisteredDetailsData;

      return userRegisteredDetailsData;
    },
    {
      initialData: initialUserRegisteredDetailData,
      enabled: isCalled,
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
      refetchOnWindowFocus: false,
    },
  );
}

function useGetUserScores(countryLanguageObj: CountryLanguageObj, isScoreAndResultReady: boolean) {
  let retryCount = INITIAL_RETRY_COUNT;
  const requestID = generateUniqueID();

  return useQuery<ScoreData, Error>(
    [DataQueryKeys.USER_SCORES],
    async () => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);
      const response = await axiosInstance.get(`${API_URLS.getScores()}/${getCountrySpecificDetails(countryLanguageObj).batch_code}`, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });

      const userScoreData: ScoreData = response.data;

      if(userScoreData.latest_session_id) {
        sessionStorage.setItem(LAST_SESSION_ID, userScoreData.latest_session_id);
      }

      return userScoreData;
    },
    {
      initialData: userScoreInitialData,
      enabled: isScoreAndResultReady,
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
      refetchOnWindowFocus: false,
    },
  );
}

function useGetReportStatus(isCalled: boolean) {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useQuery<ReportStatusData, Error>(
    [DataQueryKeys.USER_REPORT_STATUS],
    async () => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);
      const response = await axiosInstance.get(API_URLS.getReportStatus(), {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });

      const userReportStatusData: ReportStatusData = response.data;

      return userReportStatusData;
    },
    {
      initialData: {
        session_id: 'string',
        is_score_ready: false
      },
      enabled: isCalled,
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
      refetchOnWindowFocus: false,
    },
  );
}

function useGetRedoGameStatus(isCalled: boolean) {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useQuery<RedoGameStatusData, Error>(
    [DataQueryKeys.USER_REDOGAME_STATUS],
    async () => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);
      const response = await axiosInstance.get(API_URLS.getRedoGameStatus(), {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });

      const userRedoGameStatusData: RedoGameStatusData = response.data;

      return userRedoGameStatusData;
    },
    {
      initialData: {
        is_allowed: false
      },
      enabled: isCalled,
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
      refetchOnWindowFocus: false,
    },
  );
}

function useGetUserReport(sessionId: string, isReportCalled: boolean) {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useQuery<string, Error>(
    [DataQueryKeys.USER_REPORT],
    async () => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);
      const response = await axiosInstance.get(`${API_URLS.getReport()}/${sessionId}`, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });

      return response.data;
    },
    {
      initialData: '',
      enabled: isReportCalled,
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
      refetchOnWindowFocus: false,
    },
  );
}

function useStartSession() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async ({ sessionId, countryLanguageObj, countryCode }: StartSessionPayload) => {

      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      const response = await axiosInstance.post(API_URLS.startOrUpdate(sessionId), {
        'user_batch_code': getCountrySpecificDetails(countryLanguageObj).batch_code,
        'country_code': countryCode
      }, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
      let locationUrl = '';
      if (response.status === 201) {
        locationUrl = response.headers.location;
      }
      return locationUrl;
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function useUpdateSession() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async ({ sessionId, payload }: UpdateSessionPayload) => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.put(API_URLS.startOrUpdate(sessionId), payload, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function useVerifyOtp() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async ({ sessionId, payload }: VerifyOtpPayload) => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      const response = await axiosInstance.post(API_URLS.verifyOtp(sessionId), payload, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });

      const verifiedOtpDataData: VerifyOtpConfig = response.data;

      return verifiedOtpDataData;
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function usePutUserRegistrationDetails() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async ({ emailIdOrSessionId, payload }: UpdateUserRegistrationPayload) => {

      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.put(API_URLS.updateUserRegistrationDetails(emailIdOrSessionId), payload, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function usePostUserConsent() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async ({ payload }: UpdateUserConsentPayload) => {

      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.post(API_URLS.updateOrDeleteUserConcent(), payload, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function useDeleteUserConsent() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async () => {

      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.delete(API_URLS.updateOrDeleteUserConcent(), {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function useDeleteUserAccount() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async () => {

      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.delete(API_URLS.deleteOrGetUserAccount(), {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function useLogoutUser() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async () => {

      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.post(API_URLS.userLogout(), null, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function useExistingUserLogin() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async ({ sessionId, payload }: UpdateSessionPayload) => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.post(API_URLS.existingUserLogin(sessionId), payload, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function useExistingUserVerification() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async ({ sessionId, payload }: VerifyOtpPayload) => {
      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      const response = await axiosInstance.post(API_URLS.existingVerifyOtp(sessionId), payload, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });

      const verifiedOtpDataData: VerifyOtpConfig = response.data;

      return verifiedOtpDataData;
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function usePostUserSurvey() {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async ({ sessionId, payload }: AddUserSurveyPayload) => {

      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.post(API_URLS.addSurvey(sessionId), payload, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

function useSendReport(countryLanguageObj: CountryLanguageObj) {
  let retryCount = INITIAL_RETRY_COUNT;

  const requestID = generateUniqueID();

  return useMutation(
    async () => {

      const updatedRequestID = appendRequestIDWithRetryCount(retryCount, requestID);

      return axiosInstance.post(API_URLS.sendReport(), {
        'user_batch_code': getCountrySpecificDetails(countryLanguageObj).batch_code,
      }, {
        headers: {
          'X-Request-ID': updatedRequestID,
        },
      });
    },
    {
      retry: (fetchCount: number, error: Error) => {
        const errorStatus = error?.response?.status;
        retryCount += INCREMENT_ONE;

        return shouldRetryAPI(errorStatus, fetchCount);
      },
    },
  );
}

export {
  useGetUserScores,
  useGetReportStatus,
  useGetRedoGameStatus,
  useGetUserReport,
  useStartSession,
  useUpdateSession,
  useVerifyOtp,
  usePutUserRegistrationDetails,
  usePostUserConsent,
  useDeleteUserAccount,
  useExistingUserLogin,
  useExistingUserVerification,
  useLogoutUser,
  useDeleteUserConsent,
  useGetUserRegisteredDetails,
  usePostUserSurvey,
  useSendReport
};

