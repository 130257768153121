const ROUTES = {
  LANDING: '/:countryCode/:languageCode',
  SUPPORT: '/:countryCode/:languageCode/support',
  SURVEY: '/:countryCode/:languageCode/survey',
  RESURVEY: '/:countryCode/:languageCode/resurvey',
  DASHBOARD: '/:countryCode/:languageCode/dashboard',
  PING: '/ping',
  LOGIN: '/:countryCode/:languageCode/login',
  TEST: '/:countryCode/:languageCode/test',
  RETEST: '/:countryCode/:languageCode/retest',
  SIGNUP: '/:countryCode/:languageCode/signup',
  VERIFICATION: '/:countryCode/:languageCode/verification',
  EXISTVERIFICATION: '/:countryCode/:languageCode/existverification',
  REENTEREMAIL: '/:countryCode/:languageCode/reenteremail',
  PRIVACY_POLICY: '/:countryCode/:languageCode/privacy_policy',
  TERMS_AND_CONDITIONS: '/:countryCode/:languageCode/terms_and_conditions',
  NOTFOUND: '/notfound',
  NOTFOUNDLANGUAGE: '/:countryCode/notfound',
  ERROR: '/:countryCode/:languageCode/error',
};

const ALLOWED_COUNTRIES = ['au', 'sg'];
const ALLOWED_LANGUAGES = ['en'];

const DEFAULT_API_TIMEOUT_IN_MILLISECONDS = 3000;
const DEFAULT_WARNING_THRESHOLD_IN_SECOND = 0;
const ONE = 1;
const TOKEN_KEY = 'token';
const TWO = 2;
const ZERO = 0;

const DYNAMIC_ID = ':id';

const CURLY_BRACE = '{}';

const API_STATUS_TYPES = {
  Error: 'error',
  Loading: 'loading',
  Success: 'success',
};

const LAST_TEN_CHARACTERS = -10;

const ACCESS_TOKEN_KEY = 'access_token';

const SESSION_ID = 'session_id';
const START_NAME = 'start_name';
const TEST_URL = 'test_url';
const REGISTRATION_DETAILS = 'registration_details';
const LOGIN_DETAILS = 'login_details';

const DEVICE_ID = 'DEVICE_ID';
const LAST_SESSION_ID = 'latest_session_id';

const CLIENT_IP_ADDRESS = 'client_ip_address';

const DECIMAL_RADIX_NUMBER = 10;
const ONE_SECOND_IN_MILLISECOND = 1000;

const FIVE_SECOND_IN_MILLISECOND = 5000;
const MINIMUM_FETCH_COUNT = 2;
const JWT_PAYLOAD_INDEX = 1;

const ERROR_401 = 401;
const ERROR_403 = 403;
const ERROR_404 = 404;
const ERROR_500 = 500;
const ERROR_501 = 501;
const ERROR_502 = 502;
const ERROR_503 = 503;
const ERROR_504 = 504;

const ERROR_409 = 409;
const ERROR_410 = 410;

const INCREMENT_ONE = 1;
const INITIAL_RETRY_COUNT = 0;

const DEBUG_TRANSLATION = '1';

const NOOFSCOREGRAPH = 5;

const NOOFOTPINPUTBOX = 6;

export const TEMP_TOKEN_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG5ldXJvd3l6ci5jb20iLCJleHAiOjE4Mzk5OTE5NTMsIm5iZiI6MTcxMDM5MTk1MywiaWF0IjoxNzEwMzkxOTUzLCJqdGkiOiJzb21lLXNlc3Npb24taWQiLCJzZXNzaW9uX2lkIjoic29tZS1zZXNzaW9uLWlkIiwiZW1haWwiOiJkZW1vQG5ldXJvd3l6ci5jb20ifQ.t3iIxcYpsn7BhYoQWSGrYJth9MeX5gSeSzMMsgpKx24';

export {
  ERROR_409,
  LOGIN_DETAILS,
  NOOFOTPINPUTBOX,
  REGISTRATION_DETAILS,
  TEST_URL,
  START_NAME,
  SESSION_ID,
  ALLOWED_COUNTRIES,
  ALLOWED_LANGUAGES,
  NOOFSCOREGRAPH,
  DEBUG_TRANSLATION,
  CURLY_BRACE,
  ONE_SECOND_IN_MILLISECOND,
  DECIMAL_RADIX_NUMBER,
  INITIAL_RETRY_COUNT,
  INCREMENT_ONE,
  ERROR_410,
  ERROR_401,
  ERROR_403,
  ERROR_404,
  ERROR_500,
  ERROR_501,
  ERROR_502,
  ERROR_503,
  ERROR_504,
  JWT_PAYLOAD_INDEX,
  MINIMUM_FETCH_COUNT,
  FIVE_SECOND_IN_MILLISECOND,
  ACCESS_TOKEN_KEY,
  API_STATUS_TYPES,
  DYNAMIC_ID,
  DEFAULT_API_TIMEOUT_IN_MILLISECONDS,
  DEFAULT_WARNING_THRESHOLD_IN_SECOND,
  ONE,
  ROUTES,
  TOKEN_KEY,
  TWO,
  ZERO,
  LAST_TEN_CHARACTERS,
  DEVICE_ID,
  LAST_SESSION_ID,
  CLIENT_IP_ADDRESS
};
