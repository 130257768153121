/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { startTransition } from 'react';
import { ACCESS_TOKEN_KEY, ERROR_500, ERROR_501, ERROR_502, ERROR_503, ERROR_504, FIVE_SECOND_IN_MILLISECOND, ROUTES } from '../constants';
import { CountryLanguageObj } from './user';
import { replaceCountryAndLanguageCode } from './utils';

const instance = axios.create({
  baseURL: (window as any)?._env_?.REACT_APP_SERVICE_BASE_URL || process.env.REACT_APP_SERVICE_BASE_URL,
  timeout: FIVE_SECOND_IN_MILLISECOND,
  headers: {
    accept: 'application/json',
    'X-App-Version': `${(window as any)?._env_?.REACT_APP_APP_VERSION || process.env.REACT_APP_APP_VERSION}`,
  },
});

instance.interceptors.request.use(config => {
  const newConfig = { ...config };
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (accessToken) {
    newConfig.headers.Authorization = `Bearer ${accessToken}`;
  }
  return newConfig;
}, error => Promise.reject(error));

instance.interceptors.response.use(
  response => response,
  error => {

    if (!error.response || (error.response && error.response.status && [ERROR_500, ERROR_501, ERROR_502, ERROR_503, ERROR_504].includes(error.response.status))) {
      const parts = window.location.pathname.split('/');

      const countryLanguageObj: CountryLanguageObj = {
        countryCode: parts[1],
        languageCode: parts[2],
        path: parts[3]
      };
      startTransition(() => {
        window.location.href = replaceCountryAndLanguageCode(ROUTES.ERROR, countryLanguageObj);
      });
    }
    return Promise.reject(error);
  }
);


export default instance;
