import React, { startTransition, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundContainer } from '@neurowyzr/nw-dragonfly-lib';
import { useLocation, useNavigate } from 'react-router-dom';
import { setThemePrefixBasedOnCountry } from '../apis/utils/user/login';
import { getCountryAndLanguageCode, replaceCountryAndLanguageCode } from '../apis/utils';
import { CountryLanguageObj } from '../apis/user';
import { ALLOWED_COUNTRIES, ALLOWED_LANGUAGES, ROUTES } from '../constants';

function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();  
  
  const location = useLocation();
  
  const countryLanguageObj: CountryLanguageObj = getCountryAndLanguageCode(location);

  const [isBtnVisible, setIsBtnVisible] = useState(false);

  useEffect(()=>{
    if(ALLOWED_COUNTRIES.includes(countryLanguageObj.countryCode) && ALLOWED_LANGUAGES.includes(countryLanguageObj.languageCode)){
      setIsBtnVisible(true);
    }else if(ALLOWED_COUNTRIES.includes(countryLanguageObj.countryCode) && !ALLOWED_LANGUAGES.includes(countryLanguageObj.languageCode)){
      setIsBtnVisible(true);
    }
  },[countryLanguageObj])
  
  const handleGoTo = () => {

    startTransition(() => {
      if(ALLOWED_COUNTRIES.includes(countryLanguageObj.countryCode) && ALLOWED_LANGUAGES.includes(countryLanguageObj.languageCode)){
        navigate(replaceCountryAndLanguageCode(ROUTES.DASHBOARD, countryLanguageObj));
      }else if(ALLOWED_COUNTRIES.includes(countryLanguageObj.countryCode) && !ALLOWED_LANGUAGES.includes(countryLanguageObj.languageCode)){
        const tempCountryLanguageObj = countryLanguageObj;
        tempCountryLanguageObj.languageCode = 'en';
        navigate(replaceCountryAndLanguageCode(ROUTES.DASHBOARD, countryLanguageObj));
      }
    });
  }

  return (
    <div data-testid='notfound-wrapper' className='h-full w-full'>
      <NotFoundContainer
        themePrefix={setThemePrefixBasedOnCountry()}
        languageContent={{
          title: t(`not_found_${isBtnVisible ? '': 'invalid_'}title`),
          desc: t(`not_found_${isBtnVisible ? '': 'invalid_'}desc`),
          btnLbl: t('not_found_btnLbl'),
        }}
        isBtnVisible={isBtnVisible}
        handleGoTo={handleGoTo}
      />
    </div>
  );
}

export default NotFound;
