const API_URLS = {
  getScores: () => '/scores',
  getReport: () => '/report',
  startOrUpdate: (sessionId: string) => `/sessions/${sessionId}`,
  verifyOtp: (sessionId: string) => `/sessions/${sessionId}/verify`,
  updateUserRegistrationDetails: (emailIdOrSessionId: string) => `/users/${emailIdOrSessionId}`,
  updateOrDeleteUserConcent: () => '/users/consent',
  deleteOrGetUserAccount: () => '/users',
  existingUserLogin: (sessionId: string) => `/sessions/${sessionId}/login`,
  existingVerifyOtp: (sessionId: string) => `/sessions/${sessionId}/login/verify`,
  userLogout: () => '/logout',
  getReportStatus: () => '/sessions/latest',
  getRedoGameStatus: () => '/sessions/allow-new',
  addSurvey: (sessionId: string) => `/sessions/${sessionId}/survey`,
  sendReport: () => '/users/reports/latest'
};

export default API_URLS;
