import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import { usePassThrough } from 'primereact/passthrough';
import Tailwind from 'primereact/passthrough/tailwind';
import { twMerge } from 'tailwind-merge';
import './App.css';
import { ROUTES } from './constants';
import NotFound from './pages/NotFound';
import PageViewTrackEvent from './components/PageViewTrackEvent';

const Ping = lazy(() => import('./pages/Ping'));
const Landing = lazy(() => import('./pages/Landing'));
const Survey = lazy(() => import('./pages/Survey'));
const Support = lazy(() => import('./pages/Support'));
const Test = lazy(() => import('./pages/Test'));
const Signup = lazy(() => import('./pages/Signup'));
const Login = lazy(() => import('./pages/Login'));
const Verification = lazy(() => import('./pages/Verification'));
const ReEnterEmail = lazy(() => import('./pages/ReEnterEmail'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const Error = lazy(() => import('./pages/Error'));


const Dashboard = lazy(() => import('./pages/Dashboard'));

// const RedirectComponent = lazy(()=> import('./components/RedirectComponent'));
const PrivateRoute = lazy(() => import('./components/PrivateRoute'));

function App() {

  const location = useLocation();

  const [desktop, setDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (desktop) {
      // Extract path from location.pathname
      const parts = location.pathname.split('/');
      const path = parts[parts.length - 1]; // Get the last part of the pathname

      // Conditionally hide body scrollbar based on the path
      if (path && path === 'dashboard') {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }

    // Clean up the effect
    return () => {
      document.body.style.overflow = 'auto'; // Reset overflow on component unmount
    };
  }, [location.pathname, desktop]);

  const CustomTailwind = usePassThrough(
    Tailwind,
    {
      progressbar: {
        root: {
          className: 'overflow-hidden relative border-0 h-6 bg-white rounded-lg w-full'
        },
        value: {
          className: 'm-0 transition-width duration-1000 ease-in-out absolute items-center border-0 flex h-full justify-center overflow-hidden'
        }
      }
    },
    {
      mergeSections: true,
      mergeProps: false,
      classNameMergeFunction: twMerge,
    }
  );

  return (
    <PrimeReactProvider value={{ unstyled: true, pt: CustomTailwind }}>
      <Suspense fallback={<div />}>
        <div className='w-full h-dvh min-h-svh flex items-center justify-center'>
          <div className='w-full h-full bg-white relative'>
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path={ROUTES.DASHBOARD} element={<Dashboard />} errorElement={<Error />} />
                <Route path={ROUTES.SUPPORT} element={<Support />} errorElement={<Error />} />
                <Route path={ROUTES.RETEST} element={<Test />} errorElement={<Error />} />
                <Route path={ROUTES.RESURVEY} element={<Survey />} errorElement={<Error />} />
              </Route>
              <Route path={ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} errorElement={<Error />} />
              <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} errorElement={<Error />} />
              <Route path={ROUTES.LANDING} element={<Landing />} errorElement={<Error />} />
              <Route path={ROUTES.SURVEY} element={<Survey />} errorElement={<Error />} />
              <Route path={ROUTES.TEST} element={<Test />} errorElement={<Error />} />
              <Route path={ROUTES.VERIFICATION} element={<Verification />} errorElement={<Error />} />
              <Route path={ROUTES.EXISTVERIFICATION} element={<Verification />} errorElement={<Error />} />
              <Route path={ROUTES.REENTEREMAIL} element={<ReEnterEmail />} errorElement={<Error />} />
              <Route path={ROUTES.SIGNUP} element={<Signup />} errorElement={<Error />} />
              <Route path={ROUTES.PING} element={<Ping />} errorElement={<Error />} />
              <Route path={ROUTES.LOGIN} element={<Login />} errorElement={<Error />} />
              <Route path={ROUTES.ERROR} element={<Error />} />
              <Route path={ROUTES.NOTFOUND} element={<NotFound />} />
              <Route path={ROUTES.NOTFOUNDLANGUAGE} element={<NotFound />} />
              <Route path='/*' element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </Suspense>
      <PageViewTrackEvent />
    </PrimeReactProvider>
  );
}

export default App;
