import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getCountryAndLanguageCode, pageViewEvent } from '../apis/utils';
import { CountryLanguageObj } from '../apis/user';
import { useGetUserScores } from '../apis/service/user';
import { ACCESS_TOKEN_KEY, LAST_SESSION_ID } from '../constants';

function PageViewTrackEvent() {
  const location = useLocation();
  const latestSessionId = sessionStorage.getItem(LAST_SESSION_ID) ?? '';
  const jwt = localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
  const countryLanguageObj: CountryLanguageObj = getCountryAndLanguageCode(location);
  useGetUserScores(countryLanguageObj, jwt.length >= 1 && latestSessionId.length === 0);

  useEffect(() => {
    const isLogged = localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
    let intervalId: NodeJS.Timeout;
    if (isLogged) {
      let intervalCount = 0;
      intervalId = setInterval(() => {
        const lastSessionId = sessionStorage.getItem(LAST_SESSION_ID);
        if (lastSessionId) {
          clearInterval(intervalId);
          pageViewEvent();
        }
        intervalCount += 1;
        if(intervalCount >= 1000) {
          clearInterval(intervalId)
        }
      }, 200);
    } else {
      pageViewEvent();
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [location]);

  return null;
}

export default PageViewTrackEvent;
