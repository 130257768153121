import { Location } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { nanoid } from 'nanoid';
import {
  ACCESS_TOKEN_KEY,
  CLIENT_IP_ADDRESS,
  DEVICE_ID,
  ERROR_401,
  ERROR_403,
  ERROR_501,
  ERROR_502,
  ERROR_503,
  ERROR_504,
  JWT_PAYLOAD_INDEX,
  LAST_SESSION_ID,
  LAST_TEN_CHARACTERS,
  LOGIN_DETAILS,
  MINIMUM_FETCH_COUNT,
  REGISTRATION_DETAILS,
  SESSION_ID,
  START_NAME,
  TEST_URL,
} from '../../constants';
import { CountryLanguageObj } from '../user/index';

declare global {
  interface Window {
    _env_: {
      [key: string]: string
    };
  }
}

const shouldRetryAPI = (
  errorStatus: number,
  fetchCount: number,
) => {
  const isUnauthorized = [ERROR_401, ERROR_403].includes(errorStatus);

  if (isUnauthorized) {
    return false;
  }

  const isServerError = [ERROR_501, ERROR_502, ERROR_503, ERROR_504].includes(errorStatus);

  if (isServerError && fetchCount <= MINIMUM_FETCH_COUNT) {
    return true;
  }
  return false;
};

const extractTokenPayloadFromJWT = (token: string) => {
  const tokenParts = token.split('.');
  const payload = tokenParts[JWT_PAYLOAD_INDEX];
  const decodedTokenPayload = atob(payload); // Decoding the base64-encoded payload
  return JSON.parse(decodedTokenPayload);
};

const getCountryAndLanguageCode = (location: Location) => {
  // Extract path from location.pathname
  const parts = location.pathname.split('/');

  return {
    countryCode: parts[1],
    languageCode: parts[2],
    path: parts[3]
  }; // Get the last part of the pathname
}

const replaceCountryAndLanguageCode = (url: string, countryLanguageObj: CountryLanguageObj) => url
  .replace(':countryCode', countryLanguageObj.countryCode)
  .replace(':languageCode', countryLanguageObj.languageCode)


// X-request-ID
const generateUniqueID = () => crypto.randomUUID().slice(LAST_TEN_CHARACTERS);
const appendRequestIDWithRetryCount = (retryCount: number, requestID: string) =>
  retryCount ? `${requestID} ${retryCount}` : `${requestID} 0`;

const convertDate = (dateInput: string) => {
  const date = new Date(dateInput);
  const day = date.getDate().toString().padStart(2, '0');
  let monthName = date.toLocaleString(undefined, { month: 'long' });
  monthName = monthName.substring(0, 3);

  return `${day} ${monthName}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const replaceCurlyBraceFromGivenArrayDataOrder = (text: string, valueFromArray: any[]) => {
  const replacedString = text.replace(/\{\}/g, () => valueFromArray.shift());
  return replacedString;
};

const sanitizeText = (text: string) => (text ? DOMPurify.sanitize(text) : '');

const clearLocalStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(SESSION_ID);
  localStorage.removeItem(START_NAME);
  localStorage.removeItem(TEST_URL);
  localStorage.removeItem(REGISTRATION_DETAILS);
  localStorage.removeItem(LOGIN_DETAILS);
  sessionStorage.removeItem(LAST_SESSION_ID)
}

const getDeviceId = () =>
  localStorage.getItem(DEVICE_ID)

const generateDeviceId = () => {
  const id = nanoid(30);
  localStorage.setItem(DEVICE_ID, id)
  return id;
}

const getClientIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json')
    const publicIP = response.data.ip;
    sessionStorage.setItem(CLIENT_IP_ADDRESS, publicIP)
  } catch (error) {
    // unable to get ip address
  }
}

const getDefaultPropertiesData = async () => {
  const deviceId = getDeviceId() ?? generateDeviceId();
  const clientIp = sessionStorage.getItem(CLIENT_IP_ADDRESS)
  if (!clientIp) {
    await getClientIpAddress();
  }
  return {
    '$screen_height': window.screen.height,
    '$screen_width': window.screen.width,
    '$device_id': deviceId,
    '$current_url': window.location.href,
    'app_version': window?._env_?.REACT_APP_APP_VERSION,
    'current_domain': window.location.hostname,
    'current_page_title': document.title,
    'current_url_path': window.location.pathname,
    'current_url_protocol': window.location.protocol,
    'current_url_search': window.location.search,
    'ip': sessionStorage.getItem(CLIENT_IP_ADDRESS),
    '$ip': sessionStorage.getItem(CLIENT_IP_ADDRESS)
  }
}

const trackEvent = async (eventData: {
  event_name: string,
  distinct_id: string | null,
  properties: object,
}) => {
  try {
    const clientIp = sessionStorage.getItem(CLIENT_IP_ADDRESS)
    if (!clientIp) {
      await getClientIpAddress();
    }
    await axios.post(`${window?._env_?.REACT_APP_TRACKING_SERVICE_BASE_URL}/v1/events/${eventData.event_name}`,
      eventData
    )
  } catch (error) {
    // unable to track event
  }

}

const pageViewEvent = async () => {
  const deviceId = getDeviceId() ?? generateDeviceId();
  const lastSessionId = sessionStorage.getItem(LAST_SESSION_ID);
  const distinctId = lastSessionId ?? `$device:${deviceId}`;
  await trackEvent({
    event_name: 'page_view',
    distinct_id: distinctId,
    properties: await getDefaultPropertiesData(),
  });
}

export {
  clearLocalStorage,
  sanitizeText,
  appendRequestIDWithRetryCount,
  extractTokenPayloadFromJWT,
  generateUniqueID,
  shouldRetryAPI,
  convertDate,
  replaceCurlyBraceFromGivenArrayDataOrder,
  getCountryAndLanguageCode,
  replaceCountryAndLanguageCode,
  trackEvent,
  getDefaultPropertiesData,
  generateDeviceId,
  getDeviceId,
  pageViewEvent,
  getClientIpAddress
};
