const enum DataQueryKeys {
  USER_LOGIN = 'UserLogin',
  USER_DETAILS = 'UserDetails',
  USER_SCORES = 'UserScores',
  USER_REPORT = 'UserReport',
  USER_REPORT_STATUS = 'UserReportStatus',
  USER_REDOGAME_STATUS = 'UserRedoGameStatus',
}

export default DataQueryKeys;
